import { computed, type Ref } from 'vue';
import type { WelcomeModalConfig } from '../models/welcome-modal.model';
import { HideWelcomeModal } from '../enums/hide-wecome-modal.enum';
import { useLocalStorage } from '@vueuse/core';
import { useModals, WelcomeModal } from '../../modal';

export const useWelcomeModal = () => {
  const modals = useModals();

  const HIDE_WELCOME_MODAL = 'hideWelcomeModal';

  const hideWelcomeModal: Ref<string | null> = useLocalStorage(HIDE_WELCOME_MODAL, null);

  const getHideWelcomeModal = computed(() => hideWelcomeModal.value);

  const openWelcomeModal = () => {
    modals.open<WelcomeModalConfig>(WelcomeModal, {
      text: 'application.greetingModal.content',
      persistent: true,
      translate: false,
      scrollable: true,
      onConfirm: async (checked) => {
        if (checked) {
          hideWelcomeModal.value = HideWelcomeModal.HIDE;
        } else {
          hideWelcomeModal.value = HideWelcomeModal.SHOW;
        }
      }
    });
  };

  return { openWelcomeModal, getHideWelcomeModal };
};
